// UserHashtagCard.jsx
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';
import UniversalContainer from '../component/UniversalContainer.jsx';
import UserStatusIndicator from '../container/UserStatusIndicator.js';
import StatefulImage from '../component/StatefulImage.jsx';

import media from '../style/media.js';
import { textColor, color } from '../style/variables.js';
import TextEllipsis from '../style/TextEllipsis.js';
import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { PUBLIC } from '../resource/resourceUrlTypeConstants.js';
import getTranslationKey from '../resource/getTranslationKey.js';

export const UserHashtagCard = ({
  userId = '',
  displayedUsername = '',
  username = '',
  category = '',
  hashtags = [],
  countries = [],
  hashtagFeedUser = '',
  publicBadgeUrlPath = '',
  shouldShowUserHashtags = false,
  index = null,
  rank = null,
  shouldShowRank = false,
  isStreaming = false,
}) => {
  const { t } = useTranslation();
  return (
    <StyledUserHashtagDetail>
      <WithIntersectionObserver key={userId} shouldKeepObserve>
        {({ isIntersecting }) => (
          <LiveStreamCardWrapper>
            <UniversalContainer
              filename="LiveStreamCard"
              userId={userId}
              streamId={userId}
              isIntersecting={isIntersecting}
              isOnUserGrid={true}
              category={category}
              index={index}
            />
            <MetaData>
              <UsernameWithRank
                to={`/user/${userId}`}
                data-element_id={ButtonId.All.ButtonUsername}
                data-tracking_payload={{
                  'discover.index': index,
                  'discover.category': category,
                }}
              >
                {shouldShowRank && <Rank>{rank}</Rank>}
                <UsernameWrapper shouldShowRank={shouldShowRank}>
                  <Username
                    to={`/user/${userId}`}
                    data-element_id={ButtonId.All.UserSquareCardName}
                    data-tracking_payload={{
                      'user.id': userId,
                      'user.username': username,
                      'discover.index': index,
                      'discover.category': category,
                    }}
                  >
                    {displayedUsername}
                  </Username>
                  {!isStreaming && (
                    <UserStatusIndicatorWrapper>
                      <UserStatusIndicator
                        userId={userId}
                        livestreamSize={12}
                        shouldShowLiveStream
                        shouldShowOnline
                      />
                    </UserStatusIndicatorWrapper>
                  )}
                  {countries.map(country => {
                    const key = getTranslationKey({ key: country });
                    return (
                      <CountryBadgeWrapper key={key}>
                        <StatefulImage>
                          <div data-key="loading" />
                          <div data-key="error" />
                          <CountryBadge
                            data-key="target"
                            src={
                              getResourceUrl({
                                path: publicBadgeUrlPath,
                                endpoint: `/${key}.svg`,
                                resourceType: PUBLIC,
                              }).href
                            }
                            alt={key}
                            width={24}
                            height={16}
                          />
                        </StatefulImage>
                      </CountryBadgeWrapper>
                    );
                  })}
                </UsernameWrapper>
              </UsernameWithRank>
              {shouldShowUserHashtags && (
                <Hashtags>
                  {hashtags.slice(0, 2).map((tag, index) => {
                    const feedName = hashtagFeedUser.replace('{HASHTAG}', tag);
                    return (
                      <HashtagLink
                        key={index}
                        to={`/user-feed-grid/${feedName}`}
                        data-element_id={ButtonId.Hashtag.ButtonUserHashtag}
                        data-tracking_payload={{
                          hashtag: tag,
                          'discover.index': index,
                          'discover.category': category,
                        }}
                      >
                        {t(`user_hashtag_${tag}`, {
                          ns: TranslationNamespace.FEED,
                        })}
                      </HashtagLink>
                    );
                  })}
                </Hashtags>
              )}
            </MetaData>
          </LiveStreamCardWrapper>
        )}
      </WithIntersectionObserver>
    </StyledUserHashtagDetail>
  );
};

UserHashtagCard.propTypes = {
  userId: PropTypes.string,
  displayedUsername: PropTypes.string,
  username: PropTypes.string,
  category: PropTypes.string,
  hashtagFeedUser: PropTypes.string,
  publicBadgeUrlPath: PropTypes.string,
  rank: PropTypes.number,
  index: PropTypes.number,
  hashtags: PropTypes.array,
  countries: PropTypes.array,
  shouldShowUserHashtags: PropTypes.bool,
  shouldShowRank: PropTypes.bool,
  isStreaming: PropTypes.bool,
};

const StyledUserHashtagDetail = styled.div`
  width: 100%;
  height: 100%;
`;

const LiveStreamCardWrapper = styled.article``;

const MetaData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 6px 8px 6px;
`;

const UsernameWithRank = styled.div`
  display: flex;
  align-items: flex-end;
`;

const UsernameWrapper = styled.div`
  max-width: calc(
    100% - ${({ shouldShowRank }) => (shouldShowRank ? 32 : 0)}px
  );
  display: flex;
  align-items: center;
  flex: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.024px;

  ${media.tablet`
    font-size: 14px;
  `}
`;

const Username = styled(Link)`
  ${TextEllipsis};
`;

const Rank = styled.span`
  margin-right: 4px;
  flex: none;
  font-size: 32px;
  font-weight: 600;
  font-style: italic;
  line-height: 1;
  color: ${color.yellow};
`;

const UserStatusIndicatorWrapper = styled.span`
  margin-left: 4px;
  height: 24px; // should be as same as UsernameWrapper 16px * 1.5
  flex: none;
  display: inline-flex;
  align-items: center;
  ${media.mobile`
    height: 21px; // should be as same as UsernameWrapper 14px * 1.5
  `};
`;

const CountryBadgeWrapper = styled.div`
  margin-left: 8px;
  flex-shrink: 0;
  width: 24px;
  height: 16px;
  ${media.tablet`
    width: 18px;
    height: 12px;
  `};
`;

const CountryBadge = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const Hashtags = styled.div`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;

const HashtagLink = styled(Link)`
  margin-right: 4px;
  display: inline-block;
  border-radius: 40px;
  padding: 2px 8px;
  background-color: rgba(221, 221, 221, 0.2);
  color: ${textColor.primary};
  line-height: 150%; /* 24px */
  letter-spacing: 0.024px;
  font-size: 16px;
  ${media.tablet`
    font-size: 12px;
  `};
`;

export default UserHashtagCard;
